"use client";
import React from "react";
import {
  checkPathName,
  IRole,
  isClient,
  USER_ROLE,
  USER_AUTH,
  HEADER_PARTNER_ROLE,
  HEADER_PARTNER_CUSTOMER_ROLE
} from "@aspen/libs";
import { Affix } from "antd";
import Image from "next/image";
import type { IInjectProps } from "@aspen/model";
import { AgencyTipsArea, Header } from "@aspen/widgets";
import { Footer } from "@aspen/ui";

const gotoGoogle = require("@aspen/assets/images/gotoGoogle.png");

const defaultProps = {};

type IProps = {
  children: React.ReactNode;
  routeConfig: IRole;
} & IInjectProps &
  Partial<typeof defaultProps>;

type IState = {};

export default class WithLayout extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }
  render() {
    const pathname = checkPathName(this.props.router?.pathname);
    const { routeConfig } = this.props;
    const _showHeader =
      routeConfig?.[pathname]?.needAuthenticated || routeConfig?.[pathname]?.showHeader || false;

    const user_auth = isClient && window.localStorage.getItem(USER_AUTH);
    const headerProps =
      user_auth == USER_ROLE.ROLE_PARTNER ? HEADER_PARTNER_ROLE : HEADER_PARTNER_CUSTOMER_ROLE;

    return (
      <>
        {_showHeader ? (
          <>
            <Header router={this.props.router} routeConfig={routeConfig} {...headerProps} />
            <AgencyTipsArea />
            <div className="App">
              {this.props.children}
              <Affix style={{ position: "fixed", bottom: 20, right: 20, zIndex: 9 }}>
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLScTitYq7cQ1TqWkjg9b_UVkJVCFM5pDyEu5eTWX7IVtdkwuZA/viewform"
                  target="_blank"
                  rel="noopener">
                  <Image unoptimized src={gotoGoogle} width={40} height={40} alt="" />
                </a>
              </Affix>
            </div>
          </>
        ) : (
          <>{this.props?.children}</>
        )}
        <Footer />
      </>
    );
  }
}
